import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CellTowerIcon from '@mui/icons-material/CellTower';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupsIcon from '@mui/icons-material/Groups';
import LanguageIcon from '@mui/icons-material/Language';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ShowChartSharpIcon from '@mui/icons-material/ShowChartSharp';
import TouchAppSharpIcon from '@mui/icons-material/TouchAppSharp';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import FunctionsIcon from '@mui/icons-material/Functions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WebIcon from '@mui/icons-material/Web';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

export function getCategoryIcon(categoryName: string) {
    let icon = ShowChartSharpIcon; // default if category unknown

    switch (categoryName) {
        case "Credit Card":
            icon = CreditCardIcon;
            break;
        case "App Downloads":
        case "Mobile App":
            icon = PhoneIphoneIcon;
            break;
        case "Foot Traffic":
            icon = CellTowerIcon;
            break;
        case "Clickstream":
            icon = TouchAppSharpIcon;
            break;
        case "Point of Sale":
            icon = PointOfSaleIcon;
            break;
        case "KPI Models":
            icon = StackedLineChartIcon;
            break;
        case "Technicals":
            icon = TrendingUpIcon;
            break;
        case "Company Fundamentals":
            icon = AccountBalanceIcon;
            break;
        case "Macroeconomic":
            icon = LanguageIcon;
            break;
        case "Insider Transactions":
            icon = ReceiptIcon;
            break;
        case "Crowding":
            icon = GroupsIcon;
            break;
        case "Market Data":
            icon = TrendingUpIcon;
            break;
        case "User Defined":
            icon = FunctionsIcon;
            break;
        case "Events":
            icon = CalendarMonthIcon;
            break;
        case "Ecommerce":
            icon = WebIcon;
            break;
        case "Search Trends":
            icon = ManageSearchIcon;
            break;
        default:
            // throw Error('Invalid month');
            icon = ShowChartSharpIcon;
    }
    return icon;

}
